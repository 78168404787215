import React from 'react';

const Pageheader = () => {
    return (
        <div>
            <hr className="border-bottom border-secondary"></hr>
            <hr className="border-bottom border-secondary pt-0 mt-0"></hr>
        </div>
    );
};

export default Pageheader;